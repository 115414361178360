<template lang="pug">
.tabs
  .tab-wrapper(
    v-for="tab of tabs"
    :key="tab.key"
    :class="isSame(tab) && '_active'"
    @click="$emit('update:modelValue', getTabKey(tab))"
  )
    .tab(:class="large && 'large'")
      span {{ tab.name }}
      old-tooltip(v-if="tab.badge" :content="tab.badgeLabel")
        .badge {{ tab.badge }}
    .border-wrapper
      .border(:class="isSame(tab) && '_active'")
  slot
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";

interface TabI {
  name: string,
  key: string,
  badge?: number,
  badgeLabel?: string,
}

export default defineComponent({
  name: "UiTabs",
  components: {
    OldTooltip,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    tabs: {
      type: Array as PropType<TabI[]>,
      default: () => ([]),
    },
    fullInfo: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    function getTabKey(tab: TabI) {
      return props.fullInfo ? tab : tab.key
    }

    function isSame(tab: TabI) {
      return tab.key === (props.fullInfo ? props.modelValue?.key : props.modelValue)
    }

    return {
      getTabKey,
      isSame,
    }
  }
})
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  padding: 4px 3px 5px 3px;
  border-radius: 2px;
  gap: 24px;
  align-items: end;

  .tab-wrapper {
    display: flex;
    flex-flow: column;
    height: 100%;

    &._active {
      .tab {
        font-weight: bold;
        color: var(--main-color-black);

        .badge {
          background-color: var(--default-blue-color);
        }
      }
    }
  }

  .tab {
    display: flex;
    align-items: center;
    gap: 8px;

    max-width: 220px;
    border-radius: 4px;

    padding: 12px 0;
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;
    color: #676c82;
    height: 100%;

    &.large {
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .border-wrapper {
    height: 3px;
  }

  .border {
    height: 2px;
    border-radius: 2px;
    padding: 0;
    background-color: #dcdfe6;
    flex-shrink: 0;

    &._active {
      height: 3px;
      background-color: var(--default-blue-color);
    }
  }

  .badge {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    font-weight: bold;
    border-radius: 9px;
    color: white;
    background-color: #79bbff;

    height: 18px;
    width: 18px;
    flex-shrink: 0;
  }
}
</style>
